export const clubList = [
	"Central",
	"Zairza",
	"Spectrum",
	"Energy",
	// "SAE",
	"Codechef",
	"GDSC",
	// "ASME",
	"Aerospace",
	"Biozo",
	"Civicon",
];
