export const lastYearImages = [
	"https://i.postimg.cc/hGT40gWz/1.webp",
	"https://i.postimg.cc/rmwbwCJV/10.webp",
	"https://i.postimg.cc/9Qys8f0h/11.webp",
	"https://i.postimg.cc/CL3Ws8F7/12.webp",
	"https://i.postimg.cc/qB5fgYS9/13.webp",
	"https://i.postimg.cc/5yGDGY8x/14.webp",
	"https://i.postimg.cc/j2CGZQwg/15.webp",
	"https://i.postimg.cc/xTJwm5Sq/16.webp",
	"https://i.postimg.cc/nzV8QtHt/17.webp",
	"https://i.postimg.cc/wBpYgmZM/18.webp",
	"https://i.postimg.cc/k4T0Qh4R/19.webp",
	"https://i.postimg.cc/MKpTJhRN/2.webp",
	"https://i.postimg.cc/3wF5RwQB/20.webp",
	"https://i.postimg.cc/X7rb839j/21.webp",
	"https://i.postimg.cc/T14X6Hvv/22.webp",
	"https://i.postimg.cc/65P9JTB4/23.webp",
	"https://i.postimg.cc/N0qBFV9g/24.webp",
	"https://i.postimg.cc/rp1crJx5/25.webp",
	"https://i.postimg.cc/85wSyzy5/26.webp",
	"https://i.postimg.cc/Y2brRbV3/27.webp",
	"https://i.postimg.cc/prGPdL87/28.webp",
	"https://i.postimg.cc/fyTwZ93m/29.webp",
	"https://i.postimg.cc/c4kJxXBH/3.webp",
	"https://i.postimg.cc/MphKRH4J/30.webp",
	"https://i.postimg.cc/FFWv31dC/31.webp",
	"https://i.postimg.cc/W3VNWKT9/32.webp",
	"https://i.postimg.cc/2SvNswHg/33.webp",
	"https://i.postimg.cc/W1D1hg57/4.webp",
	"https://i.postimg.cc/c1mHjsKf/5.webp",
	"https://i.postimg.cc/x8k4KfhB/6.webp",
	"https://i.postimg.cc/mDpnzzXQ/7.webp",
	"https://i.postimg.cc/xdRhQFLp/8.webp",
	"https://i.postimg.cc/bv8WKjS2/9.webp",
];
